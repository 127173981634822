import { storageGet, storageSet } from '@/utils/storage'
import { getUUID } from '@/api/auth'
import { getPlatform } from "../../utils/utils";
import {AppClientKey} from "../../const/app";

export default {
    namespaced: true,
    state: {
        AppClient: null,
    },
    mutations: {
        setState: (state, { key, value }) => {
            state[key] = value
        },
        SET_APP_CLIENT: (state, AppClient) => {
            state.AppClient = AppClient
        },
    },
    actions: {
        async appClient({ state, commit }) {
            // 获取客户端标识
            let AppClient = storageGet(AppClientKey) || ''
            const platform = getPlatform()

            // 此处非仅单独存储UUID，要与platform保持关联，故本地缓存直接存储了整一个AppClient的信息
            // 倘若后续后端需要统计某个平台的使用情况，该种方式比较好
            // 但目前观察后端代码UUID暂没有与平台强关联，则先做特殊处理，后续有调整再优化

            // 此处特地做注释主要考虑到的场景：
            //  -- 目前仅仅开通了某几个平台，如微信公众号、企业微信，后续需要开通钉钉时，
            //      但上面的getPlatform()方法体并没有支持到钉钉平台，则会默认返回一个wx的标识永久存储到AppClient本地缓存
            //      这种场景就会存在问题，故这里获取到AppClient需要特殊判断下存储的平台是否和当前平台一致，不一致则更新本地缓存
            if (AppClient) {
                const AppArr = AppClient.split('/');
                if (AppArr[0] !== `yy-${platform}`) {
                    AppArr[0] = `yy-${platform}`
                    AppClient = AppArr.join('/');
                    storageSet(AppClientKey, AppClient)
                }
            }

            if (!AppClient) {
                AppClient = `yy-${platform}/zh-CN/${import.meta.env.VITE_APP_CLIENT_VERSION}`
                try {
                    const { code, data } = await getUUID(AppClient)
                    if (!code && data.uuid) {
                        AppClient = `${AppClient}/${data.uuid}`
                        storageSet(AppClientKey, AppClient)
                    }
                } catch (e) {
                    console.error('[AppClient]', e)
                }
            }
            if (!state.AppClient) commit('SET_APP_CLIENT', AppClient)
        }
    }
}
