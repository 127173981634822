import router from './router'
import store from './store'
import {getPlatform, oAuthUrl, parseQuery} from "./utils/utils";
import {Platform, PlatformMap, PlatformNumMap, PlatformText} from "./const/auth";
import { storageGet } from '@/utils/storage';
import {AppIDKey, OAuthUrlKey} from "./const/app";
import {storageRemove, storageSet} from "./utils/storage";

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title

    // 动态设置双指放大缩小
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    meta.name = 'viewport'
    meta.content = 'width=device-width, initial-scale=1.0,user-scalable=no'
    if (to.meta.scalable) {
        meta.content = 'width=device-width, initial-scale=1.0'
    }
    head[0].appendChild(meta)

    // step1.设置appClient
    await store.dispatch('app/appClient')

    // step2. 可能带有platform & app_id 参数，做校验
    // 如果platform和当前浏览器不一致，则提示使用xxxx浏览器打开
    const platform = getPlatform()
    if (to.query.platform && PlatformMap[platform] !== +to.query.platform) {
        next({ ...to, path: '/error', query: { text: `请使用${PlatformText[PlatformNumMap[+to.query.platform]]}打开` }})
        return
    }
    // 如果 app_id和当前不一致，则替换掉当前本地缓存记录的app_id，清空token等用户信息
    if (to.query.app_id && storageGet(AppIDKey) !== to.query.app_id) {
        await store.dispatch('auth/clearLoginInfo')
        await store.commit('auth/SAVE_APP_ID', to.query.app_id)
    }

    // 特殊处理，路由携带了platform & app_id参数，则重新登录授权，主要是处理切换账号登录信息问题
    if (to.query.platform && to.query.app_id) {
        await store.dispatch('auth/clearLoginInfo')
        delete to.query.platform
        delete to.query.app_id
        next({ ...to, replace: true})
        return
    }

    if (to.path === '/error') { // 错误页直接跳
        next()
        return
    } else if (to.path === '/sign/callback') { // 授权回调
        const { data } = await store.dispatch('auth/signCallback',
            { code: to.query.code, state: to.query.state })
        if (data.should_bind) { // 需要绑定u8
            next({ ...to, path: '/bind', replace: true})
        } else {
            const url = storageGet(OAuthUrlKey)
            next({ ...to, path: url || '/approval', query: parseQuery(url), replace: true})
        }
        storageRemove(OAuthUrlKey)
        return
    }

    // step3.如果没有token，则唤起登录流程获取token
    if (!store.state.auth.token) {
        storageSet(OAuthUrlKey, to.fullPath)
        const { data } = await store.dispatch('auth/signRedirect')
        location.href = data.redirect_url
        return
    }

    // step4.匹配不到路由，则往审批列表页面跳
    if (to.matched.length === 0) {
        next({ ...to, path: '/approval', replace: true})
        return
    }

    // step5.其他情景正常流程继续往下走，跳到用户目标路由
    next()
})
