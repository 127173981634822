import request from '@/utils/request'

/**
 * 获取UUID
 * @param AppClient
 * @returns {*}
 */
export function getUUID(AppClient) {
    return request({
        url: 'uuid',
        headers: {
            'X-App-Client': AppClient
        }
    })
}

/**
 * oAuth去登录
 */
export function signRedirect() {
    return request({
        url: 'sign/redirect'
    })
}

/**
 * oAuth回调授权
 * @param params
 * @returns {*}
 */
export function signCallback(params) {
    return request({
        url: 'sign/callback',
        params
    })
}

/**
 * 绑定U8
 * @param data
 * @returns {*}
 */
export function signBind(data) {
    return request({
        url: 'sign/bind',
        method: 'POST',
        data
    })
}