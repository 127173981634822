import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout/index.vue'

export const mainRouter = [
    {
        path: '/bind',
        meta: {title: '绑定U8'},
        component: () => import('@/views/Bind/index.vue')
    },
    {
        path: '/sign/callback',
        meta: {title: '授权回调'},
    },
    {
        path: '/error',
        meta: {title: '不支持的操作'},
        component: () => import('@/views/Error/index.vue')
    },
    // {
    //     path: '/404',
    //     meta: {title: '404', icon: ''},
    //     component: () => import('@/views/Login/index.vue')
    // },
    {
        path: '/',
        component: Layout,
        meta: {title: '审批列表'},
        redirect: '/approval',
        children: [
            {
                path: '/approval',
                meta: {title: '审批列表'},
                component: () => import('@/views/Approval/index.vue')
            },
            {
                path: '/approval/detail/:id',
                meta: {title: '审批详情'},
                component: () => import('@/views/Approval/detail.vue')
            },
            {
                path: '/approval/view/:id',
                meta: {title: '查看单据详情', scalable: true},
                component: () => import('@/views/Approval/view.vue')
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(''),
    routes: mainRouter,
})

export default router
