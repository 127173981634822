import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { storageGet } from '@/utils/storage'
import { TokenExpired, NotBind } from '@/const/auth.js'
import {Toast} from "vant";
import {Special} from "../const/auth";
import {storageSet} from "./storage";
import {OAuthUrlKey} from "../const/app";
import {oAuthUrl} from "./utils";

const service = axios.create({
  baseURL: `${import.meta.env.VITE_APP_BASE_API}/staff/`,
  timeout: 30000, // request timeout
})

// 发起请求之前的拦截器
service.interceptors.request.use(
  config => {
    // 如果有token 就携带tokon
    const token = storageGet('TOKEN')
    if (token) {
      config.headers['Authorization'] = token
    }
    if (store.getters.AppClient) {
      config.headers['X-App-Client'] = store.getters.AppClient
    }
    if (store.getters.AppID) {
      config.headers['X-App-Id'] = store.getters.AppID
    }
    return config
  },
  error => Promise.reject(error),
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status !== 200) {
        Toast({
            message: response.message,
            type: 'warning',
            duration: 1500,
        })
      return Promise.reject(new Error(response.message || 'Error'))
    } else if (res.code) {
        if (Special === res.code) { // 后端指定错误码跳转到错误页展示错误文案
            Toast.clear()
            router.push('/error?text=' + res.msg)
        } else {
            Toast({
                message: res.msg,
                type: 'warning',
                duration: 1500,
                onClose: () => {
                    if (NotBind === res.code) { // 未绑定U8
                        router.replace('/bind')
                    } else if(TokenExpired === res.code) {  // 登錄失效，重新去登錄
                        storageSet(OAuthUrlKey, router.fullPath)
                        store.dispatch('auth/clearLoginInfo')
                        location.reload()
                    }
                },
            })
        }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    return Promise.reject(error)
  },
)
export default service
