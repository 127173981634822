import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vant, { Locale, Lazyload } from 'vant'
import 'vant/lib/index.css';
import zhCN from 'vant/lib/locale/lang/zh-CN'
import './permission.js'
import './assets/style/reset.scss'
import { globalErrorHandle } from './utils/eventLisen.js'

Locale.use('zh-CN', zhCN)

const app = createApp(App)

globalErrorHandle(app, router)

app.use(router).use(store).use(Vant).use(Lazyload, {
    lazyComponent: true,
}).mount('#app')
