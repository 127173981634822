export const storageGet = name => {
  const data = localStorage.getItem(name)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}

export const storageSet = (name, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  localStorage.setItem(name, value)
}

export const storageRemove = name => {
  localStorage.removeItem(name)
}
