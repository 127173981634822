import {Platform} from "../const/auth";
/**
 * 获取平台标识
 */
export function getPlatform() {
    const ua = navigator.userAgent;

    if (ua.includes('wxwork')) { // 企业微信
        return Platform.QY
    } else if (ua.includes('DingTalk')) {
        return Platform.DD
    }

    // 默认微信
    return Platform.MP
}

/**
 * 解析url的query参数为json
 * @param url
 * @returns {{}}
 */
export function parseQuery(url) {
    let keyValue = [];
    let result = {};
    const hashes = url.slice(url.indexOf('?') + 1).split('&');
    for (const i in hashes) {
        keyValue = hashes[i].split('=');
        result[keyValue[0]] = keyValue[1]
    }
    return result
}

export function oAuthUrl(to) {
    let url = to.path + '?';
    Object.keys((to.query || {})).forEach(item => {
        if (item !== 'platform' && item !== 'app_id') { // 移除参数，处理死循环
            url += item + "=" + to.query[item] + "&"
        }
    })
    return url.substring(0, url.length-1)
}