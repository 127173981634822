/**
 * 授权登录错误码
 * @type {number}
 */
export const TokenExpired   = 104000 // 请重新登录
export const NotBind        = 104010 // 未绑定U8
export const Special        = 105000 // 特定的错误码，需要跳转到页面显示后端返回的错误文案

/**
 * 授权登录错误码数组
 * @type {(number|number)[]}
 */
export const AuthErrs = [
    TokenExpired,
    NotBind,
    Special,
]

/**
 * app_client 平台标识
 */
export const Platform = {
    XCX: 'xcx', // 微信小程序
    MP: 'mp', // 微信公众号
    QY: 'qy', // 企业微信
    DD: 'dd', // 钉钉
    PC: 'pc', // pc网页版
}

/**
 * 后端平台码映射
 */
export const PlatformMap = {
    [Platform.MP]: 1,
    [Platform.QY]: 2,
    [Platform.DD]: 4,
}

/**
 * 后端平台码映射
 */
export const PlatformNumMap = {
    1: Platform.MP,
    2: Platform.QY,
    4: Platform.DD
}

/**
 * 平台文案
 */
export const PlatformText = {
    [Platform.MP]: '微信公众号',
    [Platform.QY]: '企业微信',
    [Platform.DD]: '钉钉',
}