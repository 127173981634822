<template>
  <router-view />
</template>

<script setup>

// This starter template is using Vue 3 experimental <script setup> SFCs
// Check out https://github.com/vuejs/rfcs/blob/script-setup-2/active-rfcs/0000-script-setup.md

import {onMounted} from "vue";

onMounted(() => {
  // v console
  // 非prod环境，第一次进入直接开启v console
  if (import.meta.env.MODE !== 'production') {
    const script = document.createElement('script')
    script.src = import.meta.env.BASE_URL + 'js/vconsole.min.js?_t=1'
    script.defer = true
    script.id = 'vConsole'
    document.head.appendChild(script)
    // eslint-disable-next-line space-before-function-paren
    document.getElementById('vConsole').onload = function () {
      // eslint-disable-next-line no-undef
      window.vConsole = new VConsole()
    }
  }

  // 通过连击行为，触发v console的开启和关闭，prod环境开启需15次，其他环境则3次
  let vConsoleTimes = 0
  let vConsoleTimer = null
  document.onclick = function() {
    vConsoleTimer && clearTimeout(vConsoleTimer)
    vConsoleTimer = setTimeout(function() {
      vConsoleTimes = 0
    }, 1000)
    vConsoleTimes++

    if (document.getElementById('vConsole') && vConsoleTimes === 5) {
      document.getElementById('vConsole').remove()
      window.vConsole && window.vConsole.destroy()
      vConsoleTimes = 0
      return
    }

    if (!document.getElementById('vConsole') &&
        (vConsoleTimes === 15 || (import.meta.env.MODE !== 'production' && vConsoleTimes === 3))
    ) {
      vConsoleTimes = 0
      const script = document.createElement('script')
      script.src = import.meta.env.BASE_URL + 'js/vconsole.min.js?_t=1'
      script.defer = true
      script.id = 'vConsole'
      document.head.appendChild(script)
      // eslint-disable-next-line space-before-function-paren
      document.getElementById('vConsole').onload = function () {
        // eslint-disable-next-line no-undef
        window.vConsole = new VConsole()
      }
    }
  }
})
</script>

<style lang="scss">
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bgColor;
  margin: 0;
  padding: 0;
}
</style>
