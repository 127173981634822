<template>
  <div class="container">
      <router-view #="{ Component }">
        <keep-alive>
          <component :is="Component" :key="key" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" :key="key"  v-if="!$route.meta.keepAlive"/>
      </router-view>
    <footer id="table-pagination"></footer>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const key = computed(() => route.path)

</script>
<style lang="scss" scoped>
.container {
}
</style>
