import { storageGet, storageSet, storageRemove } from '@/utils/storage' //storageGet和storageSet是封装的操作localStorage的方法
import { signRedirect, signCallback } from '@/api/auth'
import {TokenKey, CustomerNameKey, AppIDKey} from "../../const/app";
import router from "../../router";
import {Toast} from "vant";

export default {
  namespaced: true,
  state: {
    token: storageGet('TOKEN') || '',
    name: storageGet('CUSTOMER_NAME') || '',
    appID: storageGet('APP_ID') || '',
  },
  mutations: {
    SAVE_LOGIN_INFO(state, data) {
      state.token = data.token || ''
      state.open_name = data.open_name || ''
      state.appID = data.app_id || ''
      storageSet(TokenKey, data.token)
      storageSet(CustomerNameKey, data.open_name)
      storageSet(AppIDKey, data.app_id)
    },
    CLEAR_LOGIN_INFO(state) {
      state.token = ''
      state.name = ''
      storageRemove(TokenKey)
      storageRemove(CustomerNameKey)
    },
    SAVE_APP_ID(state, appID) {
      state.appID = appID
      storageSet(AppIDKey, appID)
    }
  },
  actions: {
    // 登录
    async signRedirect({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await signRedirect()
          if (!res.code) {
            resolve(res)
          } else {
            reject({ code: res.code, message: res.msg })
          }
        } catch (error) {
          Toast.fail(error.message)
          reject(error)
        }
      })
    },
    // 登录回调
    async signCallback({ commit }, params) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await signCallback(params)
          if (!res.code) {
            commit('SAVE_LOGIN_INFO', res.data)
            resolve(res)
          } else {
            reject({ code: res.code, message: res.msg })
          }
        } catch (error) {
          Toast.fail(error.message)
          reject(error)
        }
      })
    },
    // 登出
    async logout({ commit }) {
      commit('CLEAR_LOGIN_INFO')
    },
    // 清除token
    clearLoginInfo({ commit }) {
      commit('CLEAR_LOGIN_INFO')
    },
  },
}
