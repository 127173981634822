export const globalErrorHandle = (vue, router) => {
    // 全局資源加載錯誤
    window.addEventListener('error', event => {
        console.error('[error]', event)
    })

    // vue 錯誤 指定一个处理函数，来处理组件渲染方法执行期间以及侦听器抛出的未捕获错误
    vue.config.errorHandler = (err, vm, info) => {
        console.error('[vue]' + info, err)
    }

    // vue-router 在导航期间每次发生未捕获的错误时都会调用该处理程序。
    // 这包括同步和异步抛出的错误、在任何导航守卫中返回或传递给 next 的错误，
    // 以及在试图解析渲染路由所需的异步组件时发生的错误。
    router.onError(err => {
        console.error('[router]onError', err)
        // if (err.message.includes('Couldn\'t resolve component')) { // 發版導致資源文件找不到，組件解析失敗抱錯
        //     dialogUpdate()
        // }
    })

    // promise error
    window.addEventListener('unhandledrejection', event => {
        console.error('[unhandledrejection]', event)
    })
}